.experience {
    background-color: rgb(22, 22, 22);
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;
  
    .top {
      text-align: center;
      color: white;
  
      p {
        font-weight: 600;
        font-size: 1rem;
      }
  
      h2 {
        font-weight: 600;
        font-size: 2.1rem;
      }
  
      marquee {
        width: 30px;
        max-width: 300px;
        display: flex;
        gap: 5px;
        margin: 6px auto;
  
        span {
          display: block;
          background-color: rgb(158, 24, 24);
          height: 10px;
          width: 30px;
        }
      }
    }
  
    .bottom {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 1rem;
  
      .box {
        background-color: lightblue;
        border-radius: 10px;
        padding: 2.5rem;
        color: rgb(0, 0, 0);
        text-align: center;
        margin: 14px 0;
        width: 100%;
        max-width: 500px;
  
        .p {
          color: rgb(56, 56, 56);
          font-size: 17px;
          font-weight: 700;
        }
  
        h2 {
          font-size: 1.9rem;
          color: rgba(0, 0, 0, 0.856);
          margin: 1rem 0;
        }
  
        .pp {
          color: navy;
          font-size: 17px;
          font-weight: 600;
        }
  
        i {
          font-size: 4rem;
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .experience {
      padding: 3rem .5rem;
  
      .top {
        h2 {
          font-size: 1.8rem;
        }
      }
  
      .bottom {
        .box {
          padding: 2rem;
      
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .experience {
      padding: 2rem 0rem;
  
      .top {
        h2 {
          font-size: 1.5rem;
        }
      }
  
      .bottom {
        .box {
          padding: 1rem;
        }
      }
    }
  }
  