.contact {
  background-color: #f8f9fa;
  padding: 2rem;

  .contact-form {
    max-width: 1000px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2, h3 {
    margin-bottom: 1rem;
  }

  input, textarea {
    width: 100%;
    max-width: 450px;
    margin: 0.5rem 0;
    padding: 0.75rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f1f3f5;
  }

  textarea {
    height: 150px;
  }

  button {
    background-color: #28a745;
    color: white;
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      background-color: #218838;
    }
  }
}

@media (max-width: 600px) {
  .contact {
    padding: 1rem;

    .contact-form {
      padding: 1rem;
    }

    input, textarea {
      max-width: 100%;
    }
  }
}
