.assembler{
    main{
        background-image: url('../assets/brk.jpg');
        background-size: cover; /* This makes the image cover the entire element */
        background-position: center; /* This centers the image */
        background-repeat: no-repeat;
        position: relative;
        // height: 100dvh;
        background-attachment: fixed;
        .bg{
            width: 100%;
            height: 100%;
            background-color: rgba(4, 65, 35, 0.507);
        }
   
    }
}