@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,500;1,600;1,700&family=Roboto+Slab:wght@300;900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
  }

body{
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    a{
        text-decoration: none;
        font-size: 17px;
        font-weight: 600;
        // font-family: 'Mulish'
    }
}
a {
    transition: color 0.3s ease;
  }
*>*{
    font-family: 'Mulish';
    // font-family: 'Poppins', sans-serif;
    // font-family: 'Roboto Slab', serif;
}