.hero {
  position: relative;
  margin: 2rem auto;
  padding: 0 0 5rem 0;

  .hero-child {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 15px;
    flex-wrap: wrap;

    .left {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      width: 100%; // Full width for responsiveness
      max-width: 500px;
      animation: slide-in 1s ease-out;

      span {
        color: white;
        font-family: 'Mulish';
        font-size: 23px;
      }

      h2 {
        color: rgb(250, 255, 223);
        font-size: 3.4rem;
      }

      p {
        color: whitesmoke;
        font-size: 20px;
      }

      a {
        color: white;
        background-color: rgb(1, 39, 1);
        width: fit-content;
        padding: 12px 20px;
        border-radius: 20px;

        &:hover {
          background-color: rgb(4, 85, 4);
        }
      }
    }

    .right {
      position: relative;
      width: 100%;
      max-width: 27rem;
      height: auto;

      img {
        width: 100%;
        height: auto;
        border-radius: 16px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.226);
        object-fit: cover;
      }
    }
  }

  .conatct-email {
    position: absolute;
    bottom: -1.5rem;
    right: 0;
    color: white;
    background-color: brown;
    padding: 12px 18px;
    display: flex;
    align-items: center;
    gap: 12px;
    text-transform: uppercase;
  }
}

.tools {
  background-color: rgba(48, 48, 116, 0.979);
  margin-top: -1.5rem;
  padding: 4rem 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  i {
    font-size: 3.2rem;
    color: white;
  }

  color: white;

  div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    span {
      font-size: 17px;
    }
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .hero {
    padding: 0;
    .conatct-email {
      padding: 15px;
    }
    .hero-child {
      .left {
        flex-basis: 100%;
        max-width: 100%; // Ensure full width on smaller screens
      }
      .right {
        flex-basis: 100%;
        max-width: 100%;
        img {
          width: 100%;
          height: auto;
          border-radius: 16px;
          object-fit: cover;
        }
      }
    }
  }
}
