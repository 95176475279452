.review-and-invest {
    background-color: white;
    padding: 2rem 0;

    .container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        margin: 2rem 0;

        img {
            width: 100%;
            max-width: 500px;
            height: auto;
            border-radius: 10px;
        }

        .box {
            width: 100%;
            max-width: 500px;
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .p {
                color: rgb(75, 75, 75);
                font-weight: 700;
                font-size: 1.5rem; /* Adjust font sizes */
            }

            .lets {
                font-size: 2.5rem;
                color: rgba(0, 0, 0, 0.685);
            }

            .text {
                font-size: 1rem;
                color: rgba(0, 0, 0, 0.644);
                font-weight: 600;
            }

            .contact {
                color: white;
                background-color: rgb(1, 39, 1);
                width: fit-content;
                padding: 0.75rem 1.5rem;
                border-radius: 20px;
                text-align: center;
                margin-top: 1rem;

                &:hover {
                    background-color: rgb(4, 85, 4);
                }
            }

            .h2 {
                font-size: 3rem;
                font-weight: 700;
                color: rgba(0, 0, 0, 0.685);
            }

            .wrap {
                display: flex;
                gap: 1rem;
                margin: 1rem 0;
                flex-wrap: wrap;

                span {
                    font-size: 2rem;
                    color: rgb(17, 17, 161);
                    font-weight: 700;
                }

                h2 {
                    font-size: 1.5rem;
                    color: rgba(0, 0, 0, 0.733);
                }
            }

            .view {
                color: green;
                font-weight: 600;
                position: relative;
                width: fit-content;
                padding: 2px 12px;
                text-align: center;
                border: 2px solid transparent;

                &:hover {
                    border-bottom-color: green;
                }

                &::before {
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 100%;
                    background-color: green;
                    top: 0;
                    left: 0;
                    margin-right: 2rem;
                    border-radius: 20px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .container {
            flex-direction: column;
            align-items: center;
            padding: 15px;
            .box {
                width: 100%;

                img {
                    max-width: 100%;
                }
                a{
                    margin-bottom: 17px;
                }
            }
        }
    }
}
