.client-review {
    background-color: white;
    padding: 5rem 0;

    .top {
        text-align: center;
        margin-bottom: 3rem;

        p {
            color: rgb(51, 51, 51);
            font-weight: 700;
            font-size: 20px;
        }

        h2 {
            font-size: 2.5rem;
            color: rgb(34, 34, 34);
        }

        marquee {
            width: 30px;

            span {
                display: block;
                background-color: rgb(199, 79, 79);
                width: 20px;
                height: 10px;
            }
        }
    }

    .bottom {
        background-color: rgb(93, 93, 170);
        padding: 2rem 1rem;
        display: flex;
        justify-content: center;
        gap: 2rem;
        flex-wrap: wrap;
        align-items: center;

        .leftimg {
            width: 100%;
            max-width: 520px;
            height: auto;
        }

        .right {
            width: 100%;
            max-width: 600px;
            padding: 15px;

            .wrap {
                display: flex;
                flex-direction: column;
                gap: 1.5rem;
                color: white;

                h3 {
                    font-size: 2rem;
                }

                .reviewer {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    span {
                        font-size: 1.1rem;
                        font-weight: 600;
                        color: rgb(233, 233, 233);
                    }

                    img {
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .bottom {
            .leftimg {
                max-width: 100%;
                object-fit: cover;
                height: auto;
            }
        }
    }
}
