.Having-difficulties{
    img{
        width: 500px;
        height: 520px;
        border-radius: 10px;
    }
    .box1{
        background-color: rgb(58, 58, 136);
        color: white;
        display: flex;
        padding: 5rem 4rem;
        place-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 1.5rem;
        .contact{
            color: white;
            background-color: rgb(1, 39, 1);
            width: fit-content;
            padding: 13px 30px;
            border-radius: 20px;
            &:hover{
                background-color: rgb(4, 85, 4);;
            }
        }
        .left{
            width: 600px;
            h4{
                font-weight: 700;
                position: relative;
                width: fit-content;
                padding: 2px 12px;
                text-align: center;
                &::before{
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 100%;
                    background-color: rgb(212, 167, 167);
                    top: 0;
                    left: 0;
                    margin-right: 12rem;
                    border-radius: 20px;
                }
            }
            h1{
                font-size: 3rem;
                margin-top: 1rem;
                font-weight: 700;
            }
        }
    }

    .box2{
        background-color: rgb(26, 24, 24);
        color: white;
        padding: 5rem;
        display: flex;
        place-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .left{
            h4{
                font-weight: 700;
                position: relative;
                width: fit-content;
                padding: 2px 12px;
                text-align: center;
                &::before{
                    position: absolute;
                    content: '';
                    width: 5px;
                    height: 100%;
                    background-color: rgb(65, 177, 102);
                    top: 0;
                    left: 0;
                    margin-right: 12rem;
                    border-radius: 20px;
                }
            }
            h1{
                font-size: 3rem;
                margin: 15px 0;
            }
        }
        .right{
            width: 600px;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
           .wrap{
            display: flex;
            flex-direction: column;
            gap: 12px;
            i{
                font-size: 3rem;
                color: rgb(66, 134, 66);
            }
            h2{
                font-size: 2rem;
            }
            span{
                color: rgba(245, 245, 245, 0.774);
            }
           }
        }
    }


    @media (max-width: 800px) {
        .box1{
         
            padding: 42px 15px;
            .left{
              padding: 0%;
            //   border: 1px solid red;

                h4{
                    font-weight: 700;
                    position: relative;
                    width: fit-content;
                    padding: 2px 12px;
                    text-align: center;
                    &::before{
                        position: absolute;
                        content: '';
                        width: 5px;
                        height: 100%;
                        background-color: rgb(212, 167, 167);
                        top: 0;
                        left: 0;
                        margin-right: 12rem;
                        border-radius: 20px;
                    }
                }
                h1{
                    font-size: 2.1rem;
                    margin-top: 1rem;
                    font-weight: 700;
                    width: 100%;
                    // border: 2px solid red;
                }
            }
        }
        .box2{
            place-content: center;
            img{
                width: 100%;
                    height: auto;
                    max-width: 100%;
                object-fit: cover;
                object-position: center;
                // width: 310px;
                // height: 330px;
                border-radius: 10px;
            }
           .left{
            h1{
                font-size: 2rem;
                // margin: 15px 0;
            }
           }
            padding: 42px 15px;
            .right{
                // padding: 15px;
                .wrap{
                    h2{
                        font-size: 1.5rem;
                    }
                }
            }
        }
    }
}