.Nav {
  z-index: 233;
  position: sticky;
  top: 0;
  background-color: rgb(82, 196, 139);

  nav {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    align-items: center;

    .logo {
      width: 100px;
      height: 100px;
    }

    .center {
      display: flex;
      gap: 13px;
      transition: all 0.3s ease;
      overflow: hidden;

      a {
        border: 2px solid transparent;
        font-weight: 600;
        font-size: 18px;
        color: whitesmoke;

        &:hover {
          border-bottom-color: rgb(182, 34, 34);
        }
      }

      .active {
        border-bottom-color: rgb(182, 34, 34);
      }

      .envelope, .details {
        display: none; // Hidden by default
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 1rem;

      .menu-toggle {
        display: none;
        background: none;
        border: none;
        color: whitesmoke;
      }

      .envelope, .details {
        
        display: block; // Show on desktop
      }
      .details{
        border: 2px solid rgb(1, 39, 1);
            padding: 9px 15px;
            border-radius: 5px;
            background-color: rgb(1, 39, 1);
            color: white;
            font-weight: 500;
            &:hover{
              background-color: transparent;
              // color:  rgb(0, 10, 0);
          }
      }
    }

    @media (max-width: 768px) {
      .center {
        display: none;
        flex-direction: column;
        background-color: rgb(36, 146, 91);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: 1rem;
        max-height: 0;

        &.open {
          display: flex;
          max-height: 500px;

          .envelope, .details {
            display: block; // Show on mobile when menu is open
          }
        }
      }

      .right {
        .envelope, .details {
          display: none; // Hide on mobile
        }

        .menu-toggle {
          display: block;
        }
      }
    }
  }
}
