.footer {
  background-color: #2d2d2d;
  color: #e8e8e8;
  padding: 2rem;
  text-align: center;

  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
    gap: 1rem;

    .left-section {
      text-align: left;
      flex: 1;

      h2 {
        font-size: 1.5rem;
        color: #00d1b2;
        margin-bottom: 0.5rem;
      }

      .email-icon {
        color: #3498db;
        margin-top: 1rem;
      }
    }

    .right-section {
      text-align: left; // Adjusted for consistency
      flex: 1;

      h3 {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      .email-icon {
        color: #e8e8e8;
        margin-right: 0.5rem;
      }
    }
  }

  .footer-bottom {
    margin-top: 2rem;
    font-size: 0.9rem;
    color: #e8e8e8;
  }

  @media(max-width: 600px) {
    .footer-content {
      flex-direction: column;
      text-align: center;

      .left-section, .right-section {
        flex: unset;
      }
    }
  }
}
