.more-about-kim{
    background: white;
    display: flex;
    place-content: space-around;
    flex-wrap: wrap;
    padding: 15px;
    padding: 7rem 0;
    .left{
        position: relative;
        width: 520px;
        // border: 1px solid red;
        height: 540px;
        img{
            position: absolute;
            width: 250px;
            height: 260px;
            border-radius: 10px;
            &:nth-child(1){
                top: 0;
                left: 0;
            }
            &:nth-child(2){
                right: 0;
                top: 0;
            }
            &:nth-child(3){
                bottom: 0;
                left: 0;
            }
            &:nth-child(4){
                right: 0;
                bottom: 0;
            }
        }
        .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; /* Center the text inside the div */
            background-color: rgb(7, 189, 98);
            color: white;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            gap: 12px;
            h2{
                font-size: 4rem;
            }
            p{
                font-size: 1.5rem;
            }
          }
        
    }
    .right{
        width: 500px;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        span{
            font-size: 2rem;
            font-weight: 600;
            color: rgb(65, 65, 65);
        }
        h2{
            font-size: 3rem;
            font-synthesis-weight: 600;
            color: rgba(0, 0, 128, 0.884);
        }
        p{
            font-size: 1.1rem;
            color: grey
            ;
        }

        .group{
            display: flex;
            // flex-direction: column;
            align-items: center;
            place-content: space-between;
            div{
                display: flex;
                flex-direction: column;
                gap: 12px;
                // align-items: center;
                i{
                    font-size: 2.5rem;
                    color: rgb(7, 189, 98);
                }
                h3{
                    color: grey;
                }
            }
        }
    }

    @media (max-width: 800px) {
        padding: 15px;
        .left{
        position: relative;
        width: 100%;
        // border: 1px solid red;
        height: 420px;
        img{
            position: absolute;
            width: 48%;
            height: 200px;
            object-fit: cover;
            object-position: center;
            border-radius: 10px;
            &:nth-child(1){
                top: 0;
                left: 0;
            }
            &:nth-child(2){
                right: 0;
                top: 0;
            }
            &:nth-child(3){
                bottom: 0;
                left: 0;
            }
            &:nth-child(4){
                right: 0;
                bottom: 0;
            }
        }
        .center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center; /* Center the text inside the div */
            background-color: rgb(7, 189, 98);
            color: white;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            gap: 12px;
            h2{
                font-size: 2.5rem;
            }
            p{
                font-size: 1rem;
            }
          }
        
    }
      }
}